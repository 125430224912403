import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18next from "i18next";

import { WAGON_CALENDAR_URL } from "../middleware/routes";
import { RootState } from "../store";
import { IWagonEventCreateState, Status } from "../types/state";
import { IWagonEvent, IWagonEventCreate } from "../types/wagonEvent";
import { axiosRequest } from "../utils/axiosRequest";
import { WagonEventPeriodicity, WasteType } from "../utils/enums";
import { adaptPostRequestForBackend } from "./utils/wagonEventAdapter";

const defaultWagonEvent: IWagonEventCreate = {
  wasteType: WasteType.mixed,
  periodicity: WagonEventPeriodicity.sevenDays,
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
};

const initWagonEventState: IWagonEventCreateState = {
  status: Status.idle,
  errorMsg: null,
  wagonEvent: defaultWagonEvent,
};

export const wagonEventCreateSlice = createSlice({
  name: "wagonEventCreateSlice",
  initialState: initWagonEventState,
  reducers: {
    setWagonEventCreateWasteType: (state: IWagonEventCreateState, action: PayloadAction<WasteType>) => {
      state.wagonEvent.wasteType = action.payload;
    },
    setWagonEventCreateStartDate: (state: IWagonEventCreateState, action: PayloadAction<string>) => {
      state.wagonEvent.startDate = action.payload;
    },
    setWagonEventCreatePeriodicity: (state: IWagonEventCreateState, action: PayloadAction<WagonEventPeriodicity>) => {
      state.wagonEvent.periodicity = action.payload;
    },
    setWagonEventCreateEndDate: (state: IWagonEventCreateState, action: PayloadAction<string | null>) => {
      state.wagonEvent.endDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postWagonEventThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(postWagonEventThunk.fulfilled, (state) => {
        state.errorMsg = null;
        state.status = Status.success;
      })
      .addCase(postWagonEventThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      });
  },
});

export interface ICreateWagonEventRequest {
  wasteType: number;
  periodicity: WagonEventPeriodicity;
  startDate: Date;
  endDate: Date;
}

export const postWagonEventThunk = createAsyncThunk<
  IWagonEvent[],
  IWagonEventCreate,
  { state: RootState; rejectValue: string }
>("wagonEvent/postWagonEventThunk", async (wagonEvent: IWagonEventCreate, { rejectWithValue }) => {
  try {
    const response = await axiosRequest.post(WAGON_CALENDAR_URL, adaptPostRequestForBackend(wagonEvent));
    return response.data;
  } catch (error: any) {
    return rejectWithValue(i18next.t("unexpectedError"));
  }
});

export const {
  setWagonEventCreateWasteType,
  setWagonEventCreateStartDate,
  setWagonEventCreatePeriodicity,
  setWagonEventCreateEndDate,
} = wagonEventCreateSlice.actions;

export const wagonEventCreateReducer = wagonEventCreateSlice.reducer;
