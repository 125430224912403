import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { WAGON_CALENDAR_URL } from "../middleware/routes";
import { RootState } from "../store";
import { IPagination } from "../types/pagination";
import { IWagonsCalendarState, Status } from "../types/state";
import { IWagonEvent, WagonsFilter } from "../types/wagonEvent";
import { axiosRequest } from "../utils/axiosRequest";
import { getSkeletonMockedArray } from "../utils/tables/getSkeletonMockedArray";
import { getSkeletonSuccessArray } from "../utils/tables/getSkeletonSuccessArray";
import { adaptResponseForFronted } from "./utils/wagonEventAdapter";
import { getStartEndDate } from "./utils/wagonsCalendarAdapter";

export const defaultPagination = {
  page: 1,
  limit: 50,
};

const defaultEvent = {
  wasteCollectionCalendarId: 0,
  date: "",
  wasteType: 1,
  periodicity: 0,
  dayOfWeek: 0,
  isInHoliday: false,
  isMocked: false,
  id: 0,
};

const defaultEvents: IWagonEvent[] = getSkeletonMockedArray(defaultEvent, 50, 0);

const initWagonsCalendarState: IWagonsCalendarState = {
  status: Status.idle,
  requestId: "",
  errorMsg: null,
  wagonEvents: defaultEvents,
  isNextPageEnable: false,
  pagination: defaultPagination,
  filter: WagonsFilter.actual,
  scrollTop: 0,
};

export const wagonsCalendarSlice = createSlice({
  name: "wagonsCalendar",
  initialState: initWagonsCalendarState,
  reducers: {
    cleanUpWagonsCalendar: (state) => {
      state.status = Status.success;
      state.errorMsg = null;
      state.wagonEvents = defaultEvents;
      state.isNextPageEnable = false;
    },
    setWagonsCalendarFilter: (state, action: PayloadAction<WagonsFilter>) => {
      state.filter = action.payload;
    },
    setWagonsCalendarScrollTop: (state, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
    setWagonsCalendarPagination: (state, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload;
    },
    resetWagonsCalendarState: () => {
      return initWagonsCalendarState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWagonsCalendarThunk.pending, (state, action) => {
        state.status = Status.requesting;
        state.requestId = action.meta.requestId;

        if (state.pagination.page > 1) {
          const mockedLocations = getSkeletonMockedArray(defaultEvent, 3, state.wagonEvents.length);
          state.wagonEvents = [...state.wagonEvents, ...mockedLocations];
        }
      })
      .addCase(getWagonsCalendarThunk.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) {
          return;
        }
        const wagonEventsFromState: IWagonEvent[] = state.wagonEvents;
        const wagonEventsFromResponse: IWagonEvent[] = action.payload;
        const newWagonEvents = getSkeletonSuccessArray(wagonEventsFromState, wagonEventsFromResponse);

        state.wagonEvents = newWagonEvents;

        let isNextPageEnable = true;
        if (action.payload.length < state.pagination.limit) {
          isNextPageEnable = false;
        }

        state.isNextPageEnable = isNextPageEnable;
        state.errorMsg = null;
        state.status = Status.success;
      })
      .addCase(getWagonsCalendarThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      });
  },
});

export interface IWagonsCalendarRequest {
  pagination: IPagination;
  year: number;
}

export interface IWagonsEventResponse {
  wasteCollectionCalendarId: number;
  date: string;
  dayOfWeek: number;
  wasteType: number;
  periodicity: number;
  isInHoliday?: boolean;
}

export const getWagonsCalendarThunk = createAsyncThunk<
  IWagonEvent[],
  IWagonsCalendarRequest | undefined,
  { state: RootState }
>("wagonsCalendar/getWagonsCalendarThunk", async (wagonsCalendarRequest, { getState }) => {
  const state = getState();
  const selectedYear = state.application.years.selectedYear.value;

  let requestObject = {
    year: selectedYear,
    pagination: defaultPagination,
  };

  if (wagonsCalendarRequest) {
    const { pagination, year } = wagonsCalendarRequest;
    requestObject = { ...requestObject, year, pagination };
  }

  const { startDate, endDate } = getStartEndDate(state.wagonsCalendar.filter, Number(requestObject.year));

  const response = await axiosRequest.get(WAGON_CALENDAR_URL, {
    params: { startDate, endDate, page: requestObject.pagination.page, limit: requestObject.pagination.limit },
  });

  return response.data.map((event: IWagonsEventResponse) => adaptResponseForFronted(event));
});

export const {
  cleanUpWagonsCalendar,
  setWagonsCalendarFilter,
  setWagonsCalendarScrollTop,
  setWagonsCalendarPagination,
  resetWagonsCalendarState,
} = wagonsCalendarSlice.actions;

export const wagonsCalendarReducer = wagonsCalendarSlice.reducer;
